import PubSubService from '../services/PubSubService';
import ConfigService from './ConfigService'

class IOTHUBService {
    
    constructor(){
        console.log("[IOTHUBService] constructor ",this);
        this.callback = this.callback.bind(this);
        this.sendPing = this.sendPing.bind(this);
        this.connect = this.connect.bind(this);
        this.socket = null;
        this.connected = false;
    }

    initSession(subscriberId){
        {
            //if (this.socket != null) this.socket.close();
            this.subscriberId = subscriberId;
            console.log("[IOTHUBService] Connecting to IOTHUB Subscriber is : ",this.subscriberId);
            this.connect();
            setInterval(this.sendPing, 10000);
        }
    }

    connect() {
        console.log("[IOTHUBService] Connect...",this.subscriberId);
        this.socket = new WebSocket(this.getWsUrl()+this.subscriberId);
        this.callback(this.socket)
    }

    getDataHubInfo(){
        const raDataLocalStorage =JSON.parse(localStorage.getItem("ra-data-local-storage"))
        const selectedHost = raDataLocalStorage.datahubhost[0].selectedHost;
        return raDataLocalStorage.endpoints[selectedHost];
        //(datahubhost.secured ? "https" : "http")+"://"+ datahubhost.host+":"+datahubhost.port+ (datahubhost.service ? "/"+datahubhost.service : "") + "/api/devices"

        return {

        }
    }

    getWsUrl(){
        let dataHubHost = this.getDataHubInfo()
        return (dataHubHost.secured ? "wss" : "ws") + "://" + dataHubHost.host+":"+dataHubHost.port+(dataHubHost.service ? "/"+dataHubHost.service : "") +"/api/ws/"
    }

    getHttpUrl(){
        let dataHubHost = this.getDataHubInfo()
        return (dataHubHost.secured ? "https" : "http") + "://" + dataHubHost.host+":"+dataHubHost.port+ (dataHubHost.service ? "/"+dataHubHost.service : "") + "/api"
    }    

    getAuthorization(){
        return "";
        return ConfigService.config.iothub.authorization;
    }   

    sendPing() {
        try {
            if (this.socket.readyState === this.socket.CLOSED) {
                console.log("[IOTHUBService] Connection closed");
                try {
                    this.socket.close()
                    this.socket = null;
                } catch (e1) {
                    console.log("[IOTHUBService] Error closing connection: ",e1);
                 }
                this.connect();
            }
            if (this.socket.readyState === this.socket.CONNECTING) { 
                console.log("[IOTHUBService] Connection connecting...");
            }
            if (this.socket.readyState === this.socket.CLOSING) { 
                console.log("[IOTHUBService] Connection closing...");
            }
            if (this.socket.readyState === this.socket.OPEN) {
                console.log("[IOTHUBService] Connection open. Send ping");
                this.socket.send(JSON.stringify({
                    resource: "systems",
                    id: null,
                    method: "ping"
                }));
            }
        } catch (e) {
            console.log("[IOTHUBService] Error sending ping: ",e);
            try {
                //this.socket.close()
            } catch (e1) {
                console.log("[IOTHUBService] Error closing socket: ",e1);
            }
            this.connect();
        }
    }

    callback (socket) {
        socket.onopen = function (event) {
            console.log("[IOTHUBService] onopen received");
            this.connected = true;
        };
        socket.onclose = function (event) {
            console.log("[IOTHUBService] onclose received");
            this.connected = false;
        };
        socket.onerror = function (event) {
            console.log("[IOTHUBService] onerror received");
            this.connected = false;
        };
        socket.onmessage = function (event) {
            this.connected = true;
            console.log("[IOTHUBService] Message received...");
            PubSubService.emit("view-update", event);
        }
    }
 }

 export default new IOTHUBService();