
import ChartWidget from '../components/widgets/ChartWidget';
import SimpleWidget from '../components/widgets/SimpleWidget';

class WidgetService {
    
    constructor(){
        this.widgetsTypes = {
            "SimpleWidget" : SimpleWidget,
            "ChartWidget" : ChartWidget
        }
    }

    getWidgetType(type){
        return this.widgetsTypes[type];
    }    

}
export default new WidgetService();