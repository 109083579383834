import React from 'react';
import Panel from './Panel';
import {Provider} from "react-redux"
import Store from '../store/configureStore'
import {useParams} from "react-router-dom";

export function SpmDisplay() {
    let {viewId} = useParams();
    console.log("[SpmDisplay]")
    return (
        <Provider store={Store}>
            <div id="app">
                <Panel name="test" id={viewId}/>
            </div>
        </Provider>
    );
}