class StatisticResolver {  

    constructor () {
        console.log("[StatisticResolver] constructor")
    }
  
    resolve(obj, path, separator='.') {
        var properties = Array.isArray(path) ? path : path.split(separator)
        return properties.reduce((prev, curr) => {
            return prev && prev[curr];
        }, obj)
    }
}

export default new StatisticResolver();
