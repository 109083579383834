import * as React from "react";
import { List, Datagrid, TextField, ReferenceField,EditButton, Edit,SimpleForm,TextInput, Create,useRecordContext,SimpleList, DeleteButton, required, NumberInput, ListButton, TopToolbar, BooleanInput, ReferenceInput, SelectInput, RecordContextProvider, DatagridBody, ListContextProvider, Pagination, ResourceContextProvider, Title } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { PostEditActions } from "../../utils";
import { TableCell, TableRow, Checkbox,Card  } from '@mui/material';
import keyBy from 'lodash/keyBy'


export const EndpointEdit = (props) => (
    <Edit {...props} actions={<PostEditActions />}>
        <EndpointForm {...props}/>
    </Edit>
);

export const EndpointCreate = (props) => (
    <Create {...props}>
        <EndpointForm {...props}/>
    </Create>
);

export const EndpointForm = (props) => (
    <SimpleForm>
        <TextInput source="host" label={"resources.dashboard.host"} validate={[required()]}/>
        <NumberInput source="port" label={"resources.dashboard.port"} validate={[required()]}/>
        <BooleanInput source="secured" label={"resources.dashboard.secured"} validate={[required()]}/>
        <TextInput source="service" label={"resources.dashboard.service"}/>
    </SimpleForm>
);

const displayHost = choice => `${choice.host}:${choice.port}/${choice.service}`;


const MyDatagridRow = ({ record, id, onToggleItem, children, selected, selectable }) => (
    <RecordContextProvider value={record}>
        <TableRow>
            {/* first column: selection checkbox */}
            <TableCell padding="none">
                <Checkbox
                    disabled={selectable}
                    checked={selected}
                    onClick={event => onToggleItem(id, event)}
                />
            </TableCell>
            {/* data columns based on children */}
            {React.Children.map(children, field => (
                <TableCell key={`${id}-${field.props.source}`}>
                    {field}
                </TableCell>
            ))}
        </TableRow>
    </RecordContextProvider>
);

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;


export const DataHubHostList = () => {
    let comments =[{'id' : 0, 'name':'test'}]
    /*
    return (
        <ResourceContextProvider value={comments}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
            </Datagrid>
        </ResourceContextProvider >
    )
    */
    const perPage = 10;
    const total = 1;
    const page = 0;
    const setPage = 1;
    const filterValues = null;
    const setFilters = null;
    const sort = { field: 'id', order: 'ASC' };
    //const { data, total, isLoading } = {comments, 1, false};
   return (
<ListContextProvider value={{ comments, total, page, perPage, setPage, filterValues, setFilters, sort }}>
            <div>
                <Title title="Book list" />
                <Card>
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="title" />
                        <TextField source="author" />
                        <TextField source="year" />
                    </Datagrid>
                </Card>
            </div>
        </ListContextProvider>    
   )


}

const redirect = (basePath, id, data) => `datahubhost/${id}`;
export const DataHubHostEdit = () => {
    return (
        <Edit redirect={redirect}>
            <SimpleForm >
                    <ReferenceInput
                        source="selectedHost"
                        label='resources.dashboard.host'
                        reference="endpoints">
                        <SelectInput optionText={displayHost} />
                    </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
}