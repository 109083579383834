import React from 'react';
import { useTranslate } from 'react-admin';

export default function ErrorPanel(props){
    const translate = useTranslate();

    return (
        <div id="errorPanel">
            <h1>{translate(props.message)}</h1>
        </div>
    );
}