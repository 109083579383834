import "whatwg-fetch";

const name = "ConfigService"; 

class ConfigService {
    STORAGE_HOSTNAME = "datahub.hostname"; 
    STORAGE_PORT = "datahub.port"; 
    STORAGE_SECURED = "datahub.secured";

    constructor () {
        console.log("constructor() - create service");
        this._initialized = false;
        this._started = false;
        this.config = null;
        this.restServerUrl = null;
    }

    get hasBeenInitialized () {
        return this._initialized;
    }

    get hasBeenStarted () {
        return this._started;
    }

    get hasConfig () {
        return this.config !== null;
    }

    initialize () {

        let that = this;

        let getConfig = () => {

            return new Promise((resolve, reject) => {
                resolve({
                    "iothub": {
                        "host" : localStorage.getItem(this.STORAGE_HOSTNAME),
                        "port" : localStorage.getItem(this.STORAGE_PORT),
                        "secured" : localStorage.getItem(this.STORAGE_SECURED) === "true"
                    }
                })
                /*
                let request = new Request("/config.js", {
                    "headers": new Headers({
                        "Content-Type": "application/json"
                    }),
                    "method": "GET"
                });

                fetch(request).then((response) => {
                    if (response.ok) {
                        response.json().then((json) => {
                            console.log("initialize() - config file successfully loaded");
                            console.log("initialize() - initialized!");
                            resolve(json);
                        });
                    } else {
                        response.json().then((json) => {
                            console.log("initialize() - error when loading the config file");
                            reject(json);
                        });
                    }
                }).catch((err) => {
                    console.log("initialize() - error");
                    reject(err);
                });
                */
            });
        };

        return new Promise((resolve, reject) => {
            if (that.initialized) {
                console.log(name, "initialize() - already initialized");
                resolve();
            } else {
                getConfig().then((config) => {
                    that.config = config;
                    that._initialized = true;
                    resolve(config);
                }).catch((err) => {
                    console.log("initialize() - error");
                    reject(err);
                });
            }
        });
    }

    start () {
        let that = this;

        return new Promise((resolve) => {
            if (that._started) {
                console.log("start() - already started");
                resolve();
            } else {
                that._started = true;
                console.log("start() - started!");
                resolve();
            }
        });
    }

    stop () {
        let that = this;

        return new Promise((resolve) => {
            if (!that._started) {
                console.log(name, "start() - already stopped");
                resolve();
            } else {
                that.config = null;
                that._initialized = false;
                that._started = false;
                console.log(name, "stop() - stopped!");
                resolve();
            }
        });
    }
}

export default new ConfigService();
