import * as React from "react";
import { List, Datagrid, TextField, ReferenceField,EditButton, Edit,SimpleForm,TextInput, Create,useRecordContext,SimpleList, DeleteButton, required, NumberInput, ListButton, TopToolbar } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { PostEditActions } from "../utils";

const DataTitle = () => {
    const record = useRecordContext();
    return <span>Data {record ? `"${record.id}"` : ''}</span>;
};

/*
const dataFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput source="userId" label="User" reference="users" />,
];
*/

export const DataList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => (
                        <ReferenceField label="User" source="userId" reference="users" />
                    )}
                />
            ) : (
                <Datagrid sx={{'& .column-button': { padding:'6px 0px' }}}>
                    <TextField source="name" label='resources.dashboard.reference'/>
                    <TextField source="sections.properties.value" label='resources.dashboard.value' textAlign={"right"}/>
                    <TextField source="description" label='resources.dashboard.description'/>
                    <DeleteButton label="" cellclassname={"column-button"}/>
                    <EditButton label="" cellclassname={"column-button"}/>
                </Datagrid>
            )}
        </List>
    );
}

export const DataEdit = () => (
    <Edit title={<DataTitle />} actions={<PostEditActions />}>
        <SimpleForm>
            <TextInput source="name" label={"Reference"}/>
            <NumberInput source="sections.properties.value" label={"Value"}/>
            <TextInput disabled source="description" label='resources.dashboard.description'/>
        </SimpleForm>
    </Edit>
);

export const DataCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="type" defaultValue={"dashboard.data"} sx={{"display" : "none"}}/>
            <TextInput source="name" label={"Reference"} validate={[required()]}/>
            <NumberInput source="sections.properties.value" label={"Value"} validate={[required()]}/>
            <TextInput source="description"/>
        </SimpleForm>
    </Create>
);