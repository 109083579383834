// in src/MyMenu.js
import { Menu } from 'react-admin';

import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import ApiIcon from '@mui/icons-material/Api';
import DatasetIcon from '@mui/icons-material/Dataset';
import BarChartIcon from '@mui/icons-material/BarChart';
import PreviewIcon from '@mui/icons-material/Preview';

export const DashboardMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.Item to="/setup" primaryText="Setup" leftIcon={<SyncIcon />}/>
        <Menu.Item to="/datahubhost/0" primaryText="Data hub" leftIcon={<SettingsIcon />}/>
        <Menu.Item to="/endpoints" primaryText="Endpoints" leftIcon={<ApiIcon />}/>
        <Menu.Item to="/data" primaryText="Statistics" leftIcon={<DatasetIcon />}/>
        <Menu.Item to="/widgets" primaryText="Widgets" leftIcon={<BarChartIcon />}/>
        <Menu.Item to="/views" primaryText="Views" leftIcon={<PreviewIcon />}/>
    </Menu>
);