import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';


const httpClient = fetchUtils.fetchJson;

const getApiUrl = (resource) => {
    const raDataLocalStorage =JSON.parse(localStorage.getItem("ra-data-local-storage"))
    const selectedHost = raDataLocalStorage.datahubhost[0].selectedHost;
    const datahubhost = raDataLocalStorage.endpoints[selectedHost];
    return (datahubhost.secured ? "https" : "http")+"://"+ datahubhost.host+":"+datahubhost.port+ (datahubhost.service ? "/"+datahubhost.service : "") + "/api/devices"
}

const getTypeFromResource = (resource) => {
    let type = "";
    switch (resource) {
        case "widgets":
            type = "dashboard.widget"
            break;
        case "data":
            type = "dashboard.data"
            break;
        case "views":
            type = "dashboard.view"
            break;
        default:
            break;
    }
    return type;
}

const dataProvider= {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const offset = (page-1) * perPage;
        const limit = page * perPage
        let type = getTypeFromResource(resource)
        let apiUrl = getApiUrl();
        //let count = getCount(`${apiUrl}?offset=${offset}&limit=${limit}&format=short&filter.type=${type}`)

        //const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const url = `${apiUrl}?offset=${offset}&limit=${limit}&format=full&view-config=true&filter.type=${type}`
        //const urlAll = `${apiUrl}?offset=${offset}&limit=${limit}&format=short&filter.type=${type}`

        /*
        return httpClient(url+"&count=true").then(({ headers, jsonCount }) => 
        (
            //let test = jsonCount;
            httpClient(url+"&test="+jsonCount).then(({ headers, json }) => ({
                data: json,
                total: jsonCount//parseInt(headers.get('content-range').split('/').pop(), 10),
            }))
        ))
        */

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: headers.get('content-range') ? parseInt(headers.get('content-range').split('/').pop(), 10) : json.length
        }))
    },


    getOne: (resource, params) => {
        let apiUrl = getApiUrl();
        return httpClient(`${apiUrl}/${params.id}?view-config=true`).then(({ json }) => ({
            data: json,
        }))
    },

    getMany: (resource, params) => {
        let apiUrl = getApiUrl();
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        let apiUrl = getApiUrl();
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) => {
      let apiUrl = getApiUrl();
        return httpClient(`${apiUrl}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },

    updateMany: (resource, params) => {
        let apiUrl = getApiUrl();
        return httpClient(`${apiUrl}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        let apiUrl = getApiUrl();

        return httpClient(`${apiUrl}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    },

    delete: (resource, params) => {
        let apiUrl = getApiUrl();

        return httpClient(`${apiUrl}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }))
    },
        
    deleteMany: (resource, params) => {
        let apiUrl = getApiUrl();
        return httpClient(`${apiUrl}/delete`, {
            method: 'POST',
            body: JSON.stringify(params.ids),
        }).then(({ json }) => ({ data: json }));
    }
};

export default dataProvider;