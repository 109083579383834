import * as React from "react";
import { List, Datagrid, TextField, ReferenceField,EditButton, Edit,SimpleForm,TextInput,ReferenceInput, Create,useRecordContext,SimpleList, DeleteButton, required, TabbedForm, FormTab, ArrayInput, SimpleFormIterator, SelectInput, NumberInput } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { JsonField, JsonInput } from "react-admin-json-view";
import DisplayViewField from "../displayViewField";
import { PostEditActions } from "../utils";

const ViewTitle = () => {
    const record = useRecordContext();
    return <span>View {record ? `"${record.id}"` : ''}</span>;
};

const widgetFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput source="userId" label="User" reference="users" />,
];

export const ViewList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => (
                        <ReferenceField label="User" source="userId" reference="users" />
                    )}
                />
            ) : (
                <Datagrid>
                    <DisplayViewField source="name" />
                    <TextField source="description" />
                    <DeleteButton label=""/>
                    <EditButton label=""/>
                </Datagrid>
            )}
        </List>
    );
}

export const ViewEdit = () => (
    <Edit title={<ViewTitle />} actions={<PostEditActions/>}>
        <TabbedForm>
            <FormTab label="resources.dashboard.global">
                <TextInput source="name" label='resources.dashboard.name' />
                <TextInput source="description" label='resources.dashboard.description'/>
            </FormTab>
            <FormTab label="resources.dashboard.widgets">
            <ArrayInput source="sections.widgets.list" label="resources.dashboard.widgets">
                <SimpleFormIterator disableReordering getItemLabel={(index) => ``}>
                    <ReferenceInput
                        source="id"
                        label='resources.dashboard.widget'
                        reference="widgets"
                        perPage={-1}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <NumberInput source="x" defaultValue={100}/>
                    <NumberInput source="y" defaultValue={100}/>
                </SimpleFormIterator>
            </ArrayInput>              
            </FormTab>            
        </TabbedForm>
    </Edit>
);

export const ViewCreate = (props) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="resources.dashboard.global">
                <TextInput source="name" label='resources.dashboard.name' />
                <TextInput source="description" label='resources.dashboard.description'/>
                <TextInput source="name" defaultValue={""} sx={{"display" : "none"}}/>
                <TextInput source="type" defaultValue={"dashboard.view"} sx={{"display" : "none"}}/>
            </FormTab>
            <FormTab label="resources.dashboard.widgets">
            <ArrayInput source="sections.widgets.list" label="resources.dashboard.widgets">
                <SimpleFormIterator disableReordering getItemLabel={(index) => ``}>
                    <ReferenceInput
                        source="id"
                        label='resources.dashboard.widget'
                        reference="widgets"
                        page={0}
                        perPage={5}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <NumberInput source="x" defaultValue={100}/>
                    <NumberInput source="y" defaultValue={100}/>
                </SimpleFormIterator>
            </ArrayInput>              
            </FormTab>            
        </TabbedForm>
    </Create>
);
