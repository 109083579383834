class PubSubService {  

    constructor () {
        console.log("[PubSubService] constructor")
        this.listeners = new Map();

        this.addListener = this.addListener.bind(this);
        this.removeListener = this.removeListener.bind(this);
        this.emit = this.emit.bind(this);

        this.APP_SERVER_CONNECTED = "app-server-connected";
        this.APP_SERVICE_DISCONNECTED = "app-server-disconnected";
        this.SDK_DISCONNECTED = "sdk-disconnected";
        this.SDK_CONNECTED = "sdk-connected";
    }
  
    addListener (label, callback) {
        this.listeners.has(label) || this.listeners.set(label, []);
        this.listeners.get(label).push(callback);
    }
  
    removeListener (label, callback) { 
        const listeners = this.listeners.get(label);
        let index = null;

        let isFunction = function isFunction (obj) {  
            return typeof obj == "function" || false;
        };

        if (listeners && listeners.length) {
            index = listeners.reduce((i, listener, index) => {
                return (isFunction(listener) && listener === callback) ? i = index : i;
            }, -1);

            if (index > -1) {
                listeners.splice(index, 1);
                this.listeners.set(label, listeners);
                return true;
            }
        }
        return false;
    }
  
    emit (label, ...args) {  
        
        let listeners = this.listeners.get(label);

        if (listeners && listeners.length) {
            listeners.forEach((listener) => {
                listener(...args); 
            });
            return true;
        }
    
        return false;
    }
}

export default new PubSubService();
