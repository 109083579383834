//import "babel-polyfill";
//import "isomorphic-fetch"

import React from 'react';
import {connect} from 'react-redux';

import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from 'react-chartjs-2';
import SpmService from "../../services/SpmService";
import 'chartjs-adapter-moment';
import PubSubService from "../../services/PubSubService";
import StatisticResolver from "../../services/StatisticResolver";
import { Link } from 'react-admin';

class ChartWidget extends React.Component {
    DEFAULT_PROPERTY_NAME = "value"
    initialized = false;
    data = {
        datasets: [
            /* FORMAT:
            {
                id: 1,
                label: 'Test 1',
                borderColor: 'rgb(255, 99, 132)',
                data: [{x: 10, y: 20}, {x: 15, y: 100}, {x: 20, y: 210}],
            },
            {
                id: 2,
                label: 'Test 2',
                borderColor: 'rgb(53, 162, 235)',
                data: [{x: 10, y: 30}, {x: 15, y: 30}, {x: 20, y: 10}],
            },
            */
        ]
    }

    constructor (props) {
        super(props);
        this.getWidgetCounters = this.getWidgetCounters.bind(this);
        this.getAllCounters = this.getAllCounters.bind(this);
        this.onDataUpdated = this.onDataUpdated.bind(this);
    }

    getWidgetCounters() {
        return this.props.widget.properties.counters.content;
    }

    getAllCounters() {
        return this.props.counters;
    }

    getCounterFromId(counterId) {
        return this.getWidgetCounters().find(counter => counterId === counter.id);
    }

    async init(){
        if(this.getAllCounters().length > 0){
            console.log("[ChartWidget] counters received")
            this.initialized = true;
            let counters = this.getWidgetCounters();
            this.subscribeToCounterUpdates(counters);
            this.data.datasets = await this.mapCountersToDatasets(counters);
            this.forceUpdate();
        }else{
            console.log("[ChartWidget] counters not received. Widget not ready")
        }
    }

    subscribeToCounterUpdates(counters){
        counters.forEach(counter => PubSubService.addListener(counter.id, this.onDataUpdated))
    }

    onDataUpdated(counterId, counterExtras){
        console.log("onDataUpdated counter",counterId)
        let datasetToUpdate = this.data.datasets.find(dataset => {
            console.log("onDataUpdated dataset",dataset, counterId)
            return dataset.id == counterId
        });
        let data = datasetToUpdate.data;
        if(data){
            let counter = this.getCounterFromId(counterId)
            let value = StatisticResolver.resolve(counterExtras, counter.path)
            console.log("onDataUpdated counter2",counter)
            data.unshift({x: new Date().toISOString(), y: value.content })
        }
    }

    async mapCountersToDatasets(counters){
        return Promise.all(counters.filter(counter => counter.display == undefined || counter.display)
                                   .map(async counter => {
            let value = this.getCounterValue(counter)
            let historyData = await this.getCounterHistory(counter)
            
            return {
                id: counter.id,
                label: counter.id,
                borderColor: counter.borderColor,
                data: [{x: new Date().toISOString(), y: value.content}, ...historyData]
            }
        }))
    }

    async getCounterHistory(counter){
        let history = await SpmService.getHistory(counter.id, this.getCounterPropertyName(counter.path))
        console.log("HISTORY =>> ",history)
        return history.map(historyItem => {
            return {
                x: historyItem.date,
                y: historyItem.property_value
            }
        })
    }

    getCounterPropertyName(counterPath){
        let splits = counterPath.split('.')
        return splits[splits.length - 1];
    }

    getCounterValue(counter){
        let counterState = this.getAllCounters().find(counterItem => counterItem.id === counter.id);
        let counterValue = "???"
        if(counterState){
            if(counter.path){
                counterValue = StatisticResolver.resolve(counterState, counter.path);
            }else{
                counterValue = StatisticResolver.resolve(counterState, 'sections.properties.value.content');
            }
        }
        return counterValue;
    }    

    render() {
        if(!this.initialized){
            this.init();
        }

        let widget = {
            "style":{
                "left" : this.props.widget.x+ "px",
                "top" : this.props.widget.y+ "px",
                "width" : this.props.widget.properties.width.content+ "px",
                "height" : this.props.widget.properties.height.content+ "px"
            },
            //global : this.props.widget.properties.style && this.props.widget.properties.style.content.global,
            title : this.props.widget.properties.style && this.props.widget.properties.style.content.title,
            content: this.props.widget.properties.style && this.props.widget.properties.style.content.content,
            footer: this.props.widget.properties.style && this.props.widget.properties.style.content.footer,
        }

        let options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: true,
                text: this.props.widget.properties.title.content,
              }
            },
            scales: this.props.widget.properties.options && this.props.widget.properties.options.content && this.props.widget.properties.options.content.scales
          }

        return (
            <Link to={"/widgets/"+this.props.id+"?viewId="+this.props.viewId}>
                <div id={"chart-widget-"+this.props.widget.id} className="widget chart-widget" style={widget.style}>
                    <Line 
                        datasetIdKey={this.props.widget.id} 
                        data={this.data}
                        options={options}
                    />
                </div>
            </Link>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log("mapStateToProps", state.view.counters)
    return {
        //"widget" : state.view.widgets.find(widget => widget.id === ownProps.id),
        "widget" : state.view.widgets  && state.view.widgets[ownProps.id],
        "counters" : state.view.counters
    }
}
export default connect(mapStateToProps)(ChartWidget)