import * as React from "react";
import { List, Datagrid, TextField, ReferenceField,EditButton, Edit,SimpleForm,TextInput,ReferenceInput, Create,useRecordContext,SimpleList, DeleteButton,useUpdate, required, ReferenceArrayInput, SelectArrayInput, FormDataConsumer, SelectInput, ArrayInput, SimpleFormIterator, NumberInput, TabbedForm, FormTab, TopToolbar, ListButton, Button, useNotify, useRedirect, SaveButton, Toolbar, useRefresh } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { PostEditActions } from "../utils";
import { ColorField, ColorInput } from 'react-admin-color-picker';
import { useNavigate  , useSearchParams} from "react-router-dom";
import { useFormContext } from 'react-hook-form';

const WidgetTitle = () => {
    const record = useRecordContext();
    return <span>Widget {record ? `"${record.id}"` : ''}</span>;
};

const widgetFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput source="userId" label="User" reference="users" />,
];

export const WidgetList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => (
                        <ReferenceField label="User" source="userId" reference="users" />
                    )}
                />
            ) : (
                <Datagrid sx={{'& .column-button': { padding:'6px 0px' }}}>
                    <TextField source="name" label='resources.dashboard.name'/>
                    <TextField source="sections.properties.type" label='resources.dashboard.type'/>
                    <TextField source="description" label='resources.dashboard.description'/>
                    <DeleteButton label="" cellclassname={"column-button"}/>
                    <EditButton label="" cellclassname={"column-button"}/>
                </Datagrid>
            )}
        </List>
    );
}

export const WidgetPostEditActions = ({ basePath, data }) => {
    const navigate  = useNavigate ();
    const [searchParams] = useSearchParams();
    let viewId = searchParams.get("viewId")
    return (
    <TopToolbar>
        <ListButton basePath={basePath} />
        { viewId ? <Button color="primary" onClick={() => navigate("/display/"+viewId)}>Back to view</Button> : null}
    </TopToolbar>
    );
}

export const WidgetEdit = (props) => {
    const [searchParams] = useSearchParams();
    let viewId = searchParams.get("viewId")

    return (
    <Edit title={<WidgetTitle />} redirect={viewId ? () => "display/"+viewId : 'list'}>
       <TabbedForm>
            <FormTab label="resources.dashboard.global">
                <TextInput source="name" label='resources.dashboard.name' />
                <TextInput source="description" label='resources.dashboard.description'/>
                <TextInput source="sections.properties.title" label='resources.dashboard.title'/>
                <TextInput source="sections.properties.type" label='resources.dashboard.type'/>   
            </FormTab>
            <FormTab label="resources.dashboard.size">
                <NumberInput source="sections.properties.width" label='resources.dashboard.width'/>
                <NumberInput source="sections.properties.height" label='resources.dashboard.height'/>    
            </FormTab>
            <FormTab label="resources.dashboard.style">
                <ColorInput source="sections.properties.backgroundColor" label='resources.dashboard.backgroundColor'/>
            </FormTab>
            <FormTab label="resources.dashboard.counters">
            <ArrayInput source="sections.properties.counters" label="resources.dashboard.counters">
                <SimpleFormIterator disableReordering getItemLabel={(index) => ``}>
                    <ReferenceInput
                        source="id"
                        label='resources.dashboard.counter'
                        reference="data"
                        perPage={10}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>                    
                    <TextInput source="reference" defaultValue={""} sx={{"display" : "none"}}/>
                    <TextInput source="path" defaultValue={"sections.properties.value"} sx={{"display" : "none"}}/>
                </SimpleFormIterator>
            </ArrayInput>              
            </FormTab>            
        </TabbedForm>        

    </Edit>
)};

export const WidgetCreate = (props) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="resources.dashboard.global">
                <TextInput source="name" label='resources.dashboard.name' validate={[required()]}/>
                <TextInput source="type" defaultValue={"dashboard.widget"} sx={{"display" : "none"}}/>
                <TextInput source="description" label='resources.dashboard.description' validate={[required()]}/>
                <TextInput source="sections.properties.title" label='resources.dashboard.title'/>
                <TextInput source="sections.properties.type" label='resources.dashboard.type'/>                
            </FormTab>
            <FormTab label="resources.dashboard.size">
                <NumberInput source="sections.properties.width" defaultValue={100} label='resources.dashboard.width'/>
                <NumberInput source="sections.properties.height" defaultValue={100} label='resources.dashboard.height'/>    
            </FormTab>
            <FormTab label="resources.dashboard.style">
                <ColorInput source="sections.properties.backgroundColor" label='resources.dashboard.backgroundColor'/>
            </FormTab>
            <FormTab label="resources.dashboard.counters">
            <ArrayInput source="sections.properties.counters" label="resources.dashboard.counters">
                <SimpleFormIterator disableReordering getItemLabel={(index) => ``}>
                    <ReferenceInput
                        source="id"
                        label='resources.dashboard.counter'
                        reference="data"
                        perPage={10}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>                    
                    <TextInput source="reference" defaultValue={""} sx={{"display" : "none"}}/>
                    <TextInput source="path" defaultValue={"sections.properties.value"} sx={{"display" : "none"}}/>
                </SimpleFormIterator>
            </ArrayInput>              
            </FormTab>            
        </TabbedForm> 
    </Create>
);
