import React from 'react';
import PubSubService from '../services/PubSubService';
import SpmService from '../services/SpmService';
import Widget from './Widget';
import {connect} from 'react-redux';
import ErrorPanel from "./ErrorPanel";

class View extends React.Component {

    constructor (props) {
        super(props);
        this.state = {};        
        this.onDataUpdated = this.onDataUpdated.bind(this);
        this.onViewUpdated = this.onViewUpdated.bind(this);
        this.onWidgetUpdated = this.onWidgetUpdated.bind(this);
        this.onCounterUpdated = this.onCounterUpdated.bind(this);
        this.updateWidgetsInState = this.updateWidgetsInState.bind(this);
        this.updateSubscriber = this.updateSubscriber.bind(this);
        this.onDataUpdated = this.onDataUpdated.bind(this);
    }
    
    componentDidMount(){
        console.log("[View] componentDidMount")
        //SpmService.deleteAllSubscribers();
        SpmService.getView(this.props.id).then(view => {
            console.log("[View] view retrieved ",view);
            let viewContent = view.sections;
            this.props.dispatch({type:"UPDATE_VIEW", value: viewContent, id:this.props.id});

            // Retrieve widgets
            let fullWidgetsPromises = view.sections.widgets.list.content.map(widget => SpmService.getWidget(widget.id));

            Promise.all(fullWidgetsPromises).then(fullWidgets => {
                this.props.dispatch({type:"ADD_WIDGETS", value: fullWidgets.map(widget => {
                    let newWidget = widget.sections;
                    newWidget.id = widget.id;
                    return newWidget;
                })});

                // Retrieve counters
                fullWidgets.forEach(widget => {
                    widget.sections.properties.counters.content.forEach(counter => {
                        SpmService.getData(counter.id).then(dataInfo => {
                            console.log("DATA => ",dataInfo); 
                            this.props.dispatch({type:"UPDATE_COUNTER", value: dataInfo, id: dataInfo.id});
                        })
                    })
                });
                SpmService.createSubscriber(this.props.id,fullWidgets).then(result => {
                    console.log("Subscriber => "+result.id);
                    this.props.dispatch({type:"UPDATE_SUBSCRIBER", value: result.id});
                    SpmService.subscribe(result.id/*this.props.view.subscriberId*/);
                    PubSubService.addListener("view-update", this.onDataUpdated);
                    setInterval(function(){
                        try{
                            console.log("[View] Refresh subscription ",result.id);
                            SpmService.refreshSubscriber(result.id)
                        }catch(error){
                            console.error("[View] Error during refresh ",error);
                        }
                    },3500 * 1000);
                }).catch(function(err) {
                    console.log("[View] Error in createSubscriber : ",err);
                });
            });
        }).catch(err =>  {
            this.setState({"errorMessage": "error:notfound:view"});
            console.log("[View] Error in getView : ",err)
        });
    }

    onDataUpdated (event) {
        let data = JSON.parse(event.data);
        console.log("Event received ",data);
        console.log("Event onDataUpdated ",data.extras);
        PubSubService.emit(data.id,data.id,data.extras)
        if(data.extras.type == "dashboard.view"){
            this.onViewUpdated(data)
        }else if(data.extras.type == "dashboard.widget"){
            this.onWidgetUpdated(data)
        }else if(data.extras.type == "dashboard.data"){
            this.onCounterUpdated(data)
        }else{
            console.log("Not for me ");
        }
    } 

    onViewUpdated(data){
        let newWidgets = data.extras.sections.widgets.list.content.map(widget => widget.id)
        let oldWidgets = Object.keys(this.props.view.widgets);
        this.props.dispatch({type:"UPDATE_VIEW", value: data.extras.sections});
        if(newWidgets.length === oldWidgets.length && newWidgets.every(function(value, index) { return value === oldWidgets[index]})){
            console.log("No widgets change")
        }else{
            this.updateWidgetsInState(newWidgets);
        }
    }

    onWidgetUpdated(data){
        this.props.dispatch({type:"UPDATE_WIDGET", value: data.extras.sections.properties, id: data.id});
        let countersId = data.extras.sections.properties.counters.content.map(counter => counter.id);
        this.updateCounterInState(countersId);
        SpmService.addTopicsToSubscriber(this.props.view.subscriberId,countersId);
    }

    updateWidgetsInState(widgetsId){
        let fullWidgetsPromises = widgetsId.map(widgetId => SpmService.getWidget(widgetId));

        Promise.all(fullWidgetsPromises).then(fullWidgets => {
            this.props.dispatch({type:"ADD_WIDGETS", value: fullWidgets.map(widget => {
                let newWidget = widget.sections;
                newWidget.id = widget.id;
                return newWidget;
            })});

            fullWidgets.forEach(widget => {
                this.updateCounterInState(widget.sections.properties.counters.content.map(counter => counter.id))
            });      

            this.updateSubscriber(fullWidgets);
        })   
    }

    updateCounterInState(countersId){
        countersId.forEach(counterId => {
            SpmService.getData(counterId).then(counterInfo => {
                console.log("COUNTER => ",counterInfo);
                this.props.dispatch({type:"UPDATE_COUNTER", value: counterInfo, id: counterInfo.id});
            })
        });
    }

    updateSubscriber(fullWidgets){
        SpmService.updateSubscriber(this.props.id,fullWidgets).then(result => {
            console.log("Subscriber updated => "+result.id);
        }).catch(function(err) {
            console.log("[View] Error in updateSubscriber : ",err);
        });
    }

    onCounterUpdated(data){
        let value = data.extras;
        value.id = data.id;
        this.props.dispatch({type:"UPDATE_COUNTER", value: value, id: data.id});
    }
    
    render() {
        console.log("VIEW = ",this.props.view)
        let viewStyle = this.props.view.config.style && this.props.view.config.style.content;
        if(this.state.errorMessage){
            return(
                <ErrorPanel message={"resources.dashboard.error.viewNotFound"}/>
            )
        }
        return (
            <div id="view" style={viewStyle}>
                {Object.keys(this.props.view.widgets)
                        .filter((widgetId)    => (this.props.view.widgets[widgetId].properties && (this.props.view.widgets[widgetId].properties.display == undefined || this.props.view.widgets[widgetId].properties.display.content)))
                        .map((widgetId)       => <Widget id={widgetId} type={this.props.view.widgets[widgetId].properties.type.content} key={widgetId}/>
                )}
            </div>
        );
    }
}

const mapStateToPros = (state) => {
    return {
        "view" :  state.view
    }
}
export default connect(mapStateToPros)(View)