const initialState = {
    "view" : {
        "config":{
            "name":"???"
        },
        "widgets" : {},
        "counters":[],
        "subscriberId" : ""
    }
}

function updateReducer(state = initialState, action){
    let nextState = {};
    let newState = {};
    console.log("[Store] action type : ",action.type);
    console.log("[Store] action value : ",action.value);
    console.log("[Store] state  : ",state);
    switch(action.type){
        case 'UPDATE_VIEW':
            newState = Object.assign(nextState, state, {
                "view": {
                    "id": action.id || state.view.id,
                    "config" : action.value.config,
                    "widgets" : action.value.widgets.list.content.reduce(function(map, widget) {
                        const {id, ...position} =  widget;
                        map[id] = {...position, properties: state.view.widgets[id] && state.view.widgets[id].properties};
                        return map;
                    }, {}),
                    "counters": state.view.counters,
                    "subscriberId": state.view.subscriberId
                }
            });
            break;
        case 'ADD_WIDGETS':
            newState = Object.assign(nextState, state, {
                "view": {
                    "id": state.view.id,
                    "config" : state.view.config,
                    "widgets" : action.value.reduce(function(map, widget) {
                        const {id, ...properties} =  widget;
                        map[id] = {x : state.view.widgets[id].x, y: state.view.widgets[id].y, ...properties};
                        return map;
                    }, {}),                    
                    "counters": state.view.counters,
                    "subscriberId": state.view.subscriberId
                }
            });       
            break;

        case 'UPDATE_WIDGET':
            newState = Object.assign(nextState, state, {
                "view": {
                    "id": state.view.id,
                    "config" : state.view.config,
                    "widgets" : Object.keys(state.view.widgets).reduce(function(map, widgetId) {
                        console.log("wigetBis ",state.view.widgets)
                        let widget = state.view.widgets[widgetId];
                        map[widgetId] = {
                            properties: (widgetId == action.id) ? action.value : widget.properties,
                            x: widget.x,
                            y: widget.y
                        }
                        return map;
                    }, {}),                    
                    "counters": state.view.counters,
                    "subscriberId": state.view.subscriberId
                }
            });  
            break;
        case 'UPDATE_COUNTER' :
            let counterIndex = state.view.counters.findIndex(counter => counter.id === action.value.id);
            if (counterIndex !== -1){
                //update
                console.log("UPDATE COUNTER: ");
                newState = Object.assign(nextState, state, {
                    "view": {
                        "id": state.view.id,
                        "config" : state.view.config,
                        "widgets" : state.view.widgets,
                        "counters": state.view.counters.map((counter) => {
                            if (counter.id === action.value.id) {
                                return Object.assign({}, counter, action.value)
                            }
                            return counter;
                        }),
                        "subscriberId": state.view.subscriberId
                    }
                })
            }else{
                //add
                console.log("ADD COUNTER: ");

                newState = Object.assign(nextState, state, {
                    "view": {
                        "id": state.view.id,
                        "config" : state.view.config,
                        "widgets" : state.view.widgets,
                        "counters": state.view.counters.concat(action.value),
                        "subscriberId": state.view.subscriberId                    
                    }
                })
            }
            break;

        case 'UPDATE_SUBSCRIBER':
            newState = Object.assign(nextState, state, {
                "view": {
                    "id": state.view.id,
                    "config" : state.view.config,
                    "widgets" : state.view.widgets,
                    "counters": state.view.counters,
                    "subscriberId": action.value
                }
            });
            break;

        default:
            newState = state;
            break;

    }
    console.log("[Store] new state  : ",newState);
    return newState;
}

export default updateReducer