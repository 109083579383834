import * as React from "react";
import { Admin, Resource, Layout, combineDataProviders, ListGuesser, CustomRoutes,defaultTheme  } from 'react-admin';
import dataHubProvider from './providers/dataHubProvider';
import localStorageDataProvider from 'ra-data-local-storage';
import DatasetIcon from '@mui/icons-material/Dataset';
import BarChartIcon from '@mui/icons-material/BarChart';
import PreviewIcon from '@mui/icons-material/Preview';
import SettingsIcon from '@mui/icons-material/Settings';
import Dashboard from "./dashboard";
import authProvider from "./providers/authProvider";
import { WidgetCreate, WidgetEdit, WidgetList } from "./resources/widgets";
import { ViewCreate, ViewEdit, ViewList } from "./resources/views";
import { DataCreate, DataEdit, DataList } from "./resources/data";
import { i18nProvider } from "./providers/i18nProvider";
import { LocalesMenuButton, AppBar } from 'react-admin';
import { Typography } from '@mui/material';
import { DashboardMenu } from "./menu";
import { DataHubHostEdit, EndpointCreate, EndpointEdit } from "./resources/settings/endpoints";
import {SpmDisplay} from "./display-app/components/SpmDisplay";
import SetupPage from "./display-app/components/SetupPage";
import { Route,useParams ,useNavigate} from "react-router-dom";
import TestPage from "./testPage";
import Notification from "./notification";
import DashboardNotification from "./notification";

export const MyAppBar = () => (
    <AppBar>
        <Typography flex="1" variant="h6" id="react-admin-title"/>
        <LocalesMenuButton
            languages={[
                { locale: 'en', name: 'English' },
                { locale: 'fr', name: 'Français' },
            ]}
        />
    </AppBar>
);

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={DashboardMenu}/>;


const configProvider = localStorageDataProvider({
  loggingEnabled: true,
  defaultData: {
      config: [
          { hostId: "10"}
      ],
      params:[
        { selectedHost: 0}
      ],
      endpoints: [
        { id: 0, host: 'localhost', port: 9000, secured : true , service: ''},
      ],
      datahubhost: [
        { selectedHost: 0}
      ]

  }
});

const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case 'data':
        case 'widgets':
        case 'views':
            return dataHubProvider;
        case 'endpoints':
        case 'params':
        case 'settings':
        case 'datahubhost':
          return configProvider;
          //return settingsProvider;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});

const theme = {
  ...defaultTheme,
  /*
  palette: {
    mode: 'light', // Switching the dark mode on is a single property value change.
  },
  components: {
      ...defaultTheme.components,
      
      RaDatagrid: {
          styleOverrides: {
            root: {
                backgroundColor: "Lavender",
                "& .RaDatagrid-headerCell": {
                    backgroundColor: "MistyRose",
                },
            }
         }
      }
  }*/
};

const App = () => {
  console.log("[App]")
  return (
  <Admin theme={theme} notification={DashboardNotification} disableTelemetry dataProvider={dataProvider} authProvider={authProvider} dashboard={Dashboard} i18nProvider={i18nProvider} layout={MyLayout}>
    <Resource name="data" list={DataList} edit={DataEdit} create={DataCreate} icon={DatasetIcon}/>
    <Resource name="widgets" list={WidgetList} edit={WidgetEdit} create={WidgetCreate} icon={BarChartIcon}/>
    <Resource name="views" list={ViewList} edit={ViewEdit} create={ViewCreate} icon={PreviewIcon}/>
    <Resource name="endpoints" list={ListGuesser} edit={EndpointEdit} create={EndpointCreate} icon={SettingsIcon}/>

    <CustomRoutes>
      <Route path="/setup" element={<SetupPage/>} />
    </CustomRoutes>

    <CustomRoutes noLayout>
      <Route path="/display/:viewId" element={<SpmDisplay/>} />
      <Route path="/test" element={<TestPage/>} />
    </CustomRoutes>
    <Resource name="datahubhost" list={ListGuesser} edit={DataHubHostEdit} icon={DatasetIcon}/>
  </Admin>
)};

export default App;