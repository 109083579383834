//import "babel-polyfill";
//import "isomorphic-fetch"

import React from 'react';
import { Link } from 'react-admin';
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";


class SimpleWidget extends React.Component {
    
    constructor (props) {
        super(props);
        this.getCounter = this.getCounter.bind(this);
        this.resolve = this.resolve.bind(this);
    }

    getCounter() {
        return this.props.widget.properties.counters.content[0];
    }

    resolve(obj, path, separator='.') {
        var properties = Array.isArray(path) ? path : path.split(separator)
        return properties.reduce((prev, curr) => {
            return prev && prev[curr];
        }, obj)
    }    

    render() {
        //const history = useNavigate();
        console.log("PROPS WIDGET => ",this.props)
        let counterConfiguration = this.getCounter()
        let counterValue = "???"
        let counterId = counterConfiguration.id;
        let counterState = this.props.counters.find(counter => counter.id === counterId);
        
        if(counterState){
            let counterPath = counterConfiguration.path;
            if(counterPath){
                counterValue = this.resolve(counterState, counterPath);
            }else{
                counterValue = this.resolve(counterState, 'sections.properties.value.content');
            }
        }
        console.log("COUNTER VALUE  => ",counterValue)
        let widget = {
            style:{
                "left" : this.props.widget.x+ "px",
                "top" : this.props.widget.y+ "px",
                "width" : this.props.widget.properties.width.content+ "px",
                "height" : this.props.widget.properties.height.content+ "px"
            },
            //global : this.props.widget.properties.style && this.props.widget.properties.style.content.global,
            title : this.props.widget.properties.style && this.props.widget.properties.style.content.title,
            content: this.props.widget.properties.style && this.props.widget.properties.style.content.content,
            footer: this.props.widget.properties.style && this.props.widget.properties.style.content.footer,
        }
        
        console.log("STYLE  => ",widget.title)

        return (
            <Link to={"/widgets/"+this.props.id+"?viewId="+this.props.viewId}>
                <div id="widget" className="widget simple-widget" style={widget.style} onClick={() => document.location.href = "http://localhost:3001/#/widgets/"+this.props.widget.id}>

                    <div id="widget-title" className="simple-widget-title" style={widget.title}>
                        {this.props.widget.properties.title.content}
                    </div>
                    <div id="widget-content" className="simple-widget-content" style={widget.content}>
                    <div id="counter">
                        {counterValue.content}
                    </div>

                    </div>
                    <div id="widget-footer" style={widget.footer}>

                    </div>
                </div>
            </Link>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log("Store widget=",state.view.widgets  && state.view.widgets[ownProps.id])
    return {
        "viewId" : state.view.id,
        //"widget" : state.view.widgets.find(widget => widget.id === ownProps.id),
        "widget" : state.view.widgets  && state.view.widgets[ownProps.id],
        "counters" : state.view.counters
    }
}
export default connect(mapStateToProps)(SimpleWidget)