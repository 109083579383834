import React from 'react';
import { Button, SimpleForm, Toolbar } from 'react-admin';
import SpmService from '../services/SpmService';

export default function SetupPage(props) {
    const schemasToImport = [
        "dashboard.view",
        "dashboard.widget",
        "dashboard.data"
    ]

    const samplesToImport = [
        "view1.json",
        "widget1.json",
        "widget2.json",
        "data1.json",
        "data2.json"
    ]

    const addDashboardFunctionnality = (_event) => {
        console.log("addDashboardFunctionnality: ",_event.target.value)

        schemasToImport.map(schema => {
            return {
                id : schema,
                filename : "/schemas/"+schema+".json"
            }}).forEach(async schema => {
            try{
                await SpmService.getSchema(schema.id)
                console.log("Schema " + schema.id+ " already exist")
            }catch(error){
                if(error.status === 404){
                    let jsonSchema = await getStaticFile(schema.filename)
                    await SpmService.createSchema(jsonSchema)
                    console.log("Schema " + schema.id+ " created")
                }
                console.log(error)
            }
            
        })
    }

    const addSample = (_event) => {
        console.log("addSample")

        samplesToImport.forEach(async sampleFilename => {
            let jsonDevice = await getStaticFile("/samples/"+sampleFilename)
            await SpmService.createDevice(jsonDevice)
            console.log("Device " + jsonDevice.id+ " created")
        })        
    }

    const getStaticFile = (filename) => {
        return new Promise((resolve, reject) => {
            let request = new Request(filename, {
                "headers": new Headers({
                    "Content-Type": "application/json"
                }),
                "method": "GET"
            });

            fetch(request).then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        console.log("schema found");
                        resolve(json);
                    });
                } else {
                    response.json().then((json) => {
                        console.log("schema not found");
                        reject(json);
                    });
                }
            }).catch((err) => {
                console.log("getSchema - error");
                reject(err);
            });
        })
    }

    const SetupToolbar = props => (
        <Toolbar {...props} >
        </Toolbar>
    );

    return (
        <SimpleForm toolbar={SetupToolbar}>
            <Button label={"Add Dashboard functionnality"} onClick={addDashboardFunctionnality}/>
            <Button label={"Add sample"} onClick={addSample}/>
        </SimpleForm>
    );
}