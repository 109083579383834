//import "babel-polyfill";
//import "isomorphic-fetch"

import React from 'react';
import WidgetService from '../services/WidgetService';
import '../styles/widgets.css'

class Widget extends React.Component {

    constructor (props) {
        super(props);
    }

    render() {
        let Component = WidgetService.getWidgetType(this.props.type);
        return (
            <Component id={this.props.id} key={this.props.id}/>
        );
    }
}

export default Widget;