import * as React from "react";
import { Link, useRecordContext } from 'react-admin';
import LaunchIcon from '@mui/icons-material/Launch';

const DisplayViewField = ({ source }) => {
    const record = useRecordContext();
    return record ? (
        <Link to={"/display/"+record.id} target="_blank">
            {record[source]}
            <LaunchIcon sx={{ width: '0.5em', height: '0.5em', paddingLeft: 2 }} />
        </Link>
    ) : null;
}

export default DisplayViewField;