import IOTHUBService from './IOTHUBService'

class SpmService {

    constructor(){
    }

    getSubscriber(id){
        return this.get(IOTHUBService.getHttpUrl()+'/subscribers/'+id, "getSubscriber");
    }

    createSubscriber(viewId, widgets){
        let subscription = this.buildSubscriberObject(viewId,widgets)
        console.log("Subscription : ",subscription);
        return this.post(IOTHUBService.getHttpUrl()+'/subscribers',subscription,"createSubscriber");
    }

    updateSubscriber(subscriberId, viewId, widgets){
        let subscription = this.buildSubscriberObject(viewId,widgets)
        console.log("Subscription : ",subscription);
        return this.put(IOTHUBService.getHttpUrl()+'/subscribers/'+subscriberId,subscription,"updateSubscriber");
    }

    buildSubscriberObject(viewId, widgets){
        let deviceTopics = [{
                "id" : viewId,
                "extras": ["sections","type"]
            }]
        widgets.forEach((widget) => {
            deviceTopics.push({
                "id" : widget.id,
                "extras" : ["sections","type"]
                });
            widget.sections.properties.counters.content.forEach((counter) => {
                let counterTopic = {
                    "id" : counter.id,
                    "extras" : ["sections","type"]
                    }
                if(deviceTopics.filter(topic => (topic.id == counter.id)).length == 0){
                    deviceTopics.push(counterTopic);
                }
            });
        });
        return {
            "name" : "SPM Subscriber",
            "device_topics" : deviceTopics,         
            "ttl" : 3600
        };       
    }
    
    addTopicsToSubscriber(subscriberId, devicesIdToSubscribe){
        this.getSubscriber(subscriberId).then(subscription => {
            let devicesIdSubscribed = subscription.device_topics.map(device => device.id);
            let devicesIdNotSubscribed = devicesIdToSubscribe.filter(deviceId => !devicesIdSubscribed.includes(deviceId))

            devicesIdNotSubscribed.forEach(deviceId => {
                subscription.device_topics.push({"id" : deviceId,"extras" : ["sections","type"]})
            })

            console.log("Subscription : ",subscription);
            return this.put(IOTHUBService.getHttpUrl()+'/subscribers/'+subscriberId,subscription,"updateSubscriber");
        })
    }    

    refreshSubscriber(subscriberId){
        return this.patch(IOTHUBService.getHttpUrl()+'/subscribers/'+subscriberId+'/refresh?ttl=3600',{},"refreshSubscriber");
    }

    deleteAllSubscribers(){
        this.get(IOTHUBService.getHttpUrl()+'/subscribers').then(result => {
            result.forEach(subscriber => this.deleteSubscriber(subscriber.id));
        })
    }

    deleteSubscriber(id){
        return this.delete(IOTHUBService.getHttpUrl()+'/subscribers/'+id, "deleteSubscriber");
    }

    getView(viewId){
        return this.get(IOTHUBService.getHttpUrl()+'/devices/'+viewId,"getView");
    }    
    
    getWidget(widgetId){
        return this.get(IOTHUBService.getHttpUrl()+'/devices/'+widgetId,"getWidget");
    }

    getData(dataId){
        return this.get(IOTHUBService.getHttpUrl()+'/devices/'+dataId,"getData");
    }   
    
    getViews(){
        return this.getDevicesByType("spm.view");
    } 

    getDevicesByType(type){
        return this.get(IOTHUBService.getHttpUrl()+'/devices?filter.type='+type,"getDevicesByType");
    }     

    async getHistory(deviceId, path){
        return this.get(IOTHUBService.getHttpUrl()+'/history/devices?filter.device_id='+deviceId+"&filter.property_name="+path,"getDevicesHistory");
    }

    subscribe(subscriberId){
        console.log("IOTHUBService.initSession => ",subscriberId);
        IOTHUBService.initSession(subscriberId);
    }   
    
    createSchema(deviceSchema){
        return this.post(IOTHUBService.getHttpUrl()+"/schemas/devices",deviceSchema, "createSchema");
    }

    getSchema(schemaName){
        return this.get(IOTHUBService.getHttpUrl()+"/schemas/devices/"+schemaName, "getSchema");
    }

    createDevice(device){
        return this.post(IOTHUBService.getHttpUrl()+"/devices",device, "createDevice");
    }    

    get(url, signature){
        return this.request('get', url, signature);
    }    

    post(url, body, signature){
        return this.request('post', url, signature, body);
    }

    put(url, body, signature){
        return this.request('put', url, signature, body);
    }

    patch(url, body, signature){
        return this.request('patch', url, signature, body);
    }

    delete(url, signature){
        return this.request('delete', url, signature);
    }

    request(method, url, signature, body){
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' ,
                    'Authorization' : IOTHUBService.getAuthorization()
                  },
                body: (method === 'post' || method === 'put' || method === 'patch') ? JSON.stringify(body) : undefined
            }).then(function(response) {
                response.status >= 200 && response.status < 300  ? resolve(response.json()) : reject(response)
            }).catch(function(err) {
                console.log("[SpmService] Error in "+ signature +"=> ",err);
                reject(err);
            });
        });
    }      

/*
    createSubscriberBis(state){
        console.log("SUBSCRIBER STATE : ",state);
        let deviceTopics = [{
                "id" : state.id,
                "filters" : [],
                "extras": ["sections","type"]
            }]

        deviceTopics.concat(state.widgets.map(widget => {
           return {
                "id" : widget.id,
                "filters" : [],
                "extras" : ["sections","type"]
                }
        }));

        deviceTopics.concat(state.counters.map(counter => {
            return {
                 "id" : counter.id,
                 "filters" : [],
                 "extras" : ["sections","type"]
                 }
         }));

        let subscriber = 
        {
            "name" : "SPM Subscriber",
            "device_topics" : deviceTopics,         
            "ttl" : 3600
        };       

        console.log("SUBSCRIBER MAP : ",subscriber);

        //return this.post(ConfigService.config.iothub.url+'/api/subscribers',subscriber,"createSubscriber");
    } 
    */    

}
export default new SpmService();