import React from 'react';
import View from './View';
import {connect} from 'react-redux';

class Panel extends React.Component {

    constructor (props) {
        super(props);
    }
    
    render() {
        return (
            <div id="panel">
                <View id={this.props.id}/>
            </div>
        );
    }
}

const mapStateToPros = (state) => {
    return {
        "view" :  state.view
    }
}
export default connect(mapStateToPros)(Panel)