// in src/i18n/en.js
import englishMessages from 'ra-language-english';

export const en = {
    ...englishMessages,
    resources: {
        dashboard: {
            data: 'Data |||| Data',
            widgets: 'Widgets',
            views : 'View |||| Views',
            reference: 'Reference',
            value: 'Value',
            name : 'Name',
            description: 'Description',
            counters: 'Counters',
            counter : 'Counter',
            global:'Global',
            position:'Position',
            style:'Style',
            title: 'Title',
            x:'x',
            y:'y',
            width:'Width',
            height:'Height',
            backgroundColor: 'Background color',
            type:'Type',
            host:'Host',
            port:'Port',
            secured:'Secured',
            service:'Service',
            size:'Dimensions',
            error : {
                viewNotFound: 'View not found'
            }
        }
    }
};