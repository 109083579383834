// in src/i18n/en.js
import frenchMessages from 'ra-language-french';

export const fr = {
    ...frenchMessages,
    resources: {
        dashboard: {
            data: 'Data |||| Data',
            widgets: 'Widget |||| Widgets',
            views : 'View |||| Views',
            reference: 'Référence'
        }
    }
};